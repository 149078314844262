import { Api } from '@campus/auth';

import { Response } from '@campus/commons';

export async function userHasVestibularManagementPermission(): Promise<
  Response<boolean>
> {
  try {
    const response = await Api.INSTANCE.getApi().get(
      '/permission/literary-work/management'
    );

    return Response.fromData(response.data, response.status);
  } catch (error) {
    throw Response.error(error);
  }
}
